<template>
  <div class="mx-auto">
    <!-- TITLE -->
    <TitleWrapper
      :filter-option="false"
      title="CON_SETTS"
      tooltip-title="CON_SETTS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- FORM VALIDATION AND FIELDS -->
    <!-- <ValidationObserver tag="div"> -->
    <div
      class="min-h-screen settings-bar pt-7 mt-3 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div v-if="!isLoading">
        <!-- V-FOR START -->
        <div v-for="(setting, index) in contactSettings" :key="index">
          <h1 class="mb-4 font-roboto text-base sm:text-lg text-text-color">
            {{ setting.title || 'Contact Info' }}
            <span class="float-right mr-6 cursor-pointer" @click="deleteSettingById(setting)">
              <icon
                class="icon opacity-50 lg:opacity-100"
                icon="crossCircle"
                height="15"
                width="15"
              />
            </span>
            <span class="float-right mr-6 cursor-pointer" @click="editSettingById(setting)">
              <icon
                class="icon opacity-50 lg:opacity-100"
                color="white"
                icon="edit"
                height="15"
                width="15"
              />
            </span>
          </h1>
          <div
            class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
          >
            <!-- DESIGNATION -->
            <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0 relative">
              <InputBox
                v-model="setting.designation"
                type="text"
                :rules="`required`"
                name="Designation"
                title="Designation"
                label="Designation"
                placeholder="Designation"
                :disabled="true"
                class="flex-1"
                is-requried="true"
              />
            </div>
            <!-- NEW PHONE NUMBER -->
            <div
              v-if="setting.new_phone"
              class="sm:pr-6 pr-0 sm:w-1/2 w-full sm:pt-0 pt-0 relative"
            >
              <span class="float-right right-0 z-50 cursor-pointer text-xs text-primary-green">
                verify
              </span>
              <PhoneNumber
                v-model="setting.new_phone_field"
                title="New Phone Number"
                :rules="`required`"
                :disabled="true"
              />
            </div>
            <!-- PHONE NUMBER -->
            <div class="sm:pr-6 pr-0 sm:w-1/2 w-full sm:pt-0 pt-4 relative">
              <span class="float-right cursor-pointer" @click="editSettingPhoneById(setting)">
                <icon
                  class="icon opacity-50 lg:opacity-100"
                  color="white"
                  icon="edit"
                  height="15"
                  width="15"
                />
              </span>
              <PhoneNumber
                v-model="setting.phone_field"
                title="Phone Number"
                :rules="`required`"
                :is-verified="setting.is_phone_approved"
                :disabled="true"
              />
            </div>
            <!-- NEW EMAIL -->
            <div
              v-if="setting.new_email"
              class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0 relative"
            >
              <span
                class="float-right absolute right-0 sm:pr-6 z-50 pr-0 cursor-pointer text-xs text-primary-green"
              >
                verify
              </span>
              <InputBox
                v-model="setting.new_email"
                type="email"
                :rules="`required`"
                name="Email"
                title="New email"
                label="Email"
                placeholder="email"
                :disabled="true"
                class="flex-1"
                is-requried="true"
              />
            </div>
            <!-- EMAIL -->
            <div class="sm:w-1/2 w-full sm:pt-0 pt-0 sm:pr-6 pr-0 relative">
              <span
                class="float-right absolute right-0 sm:pr-6 z-50 pr-0 cursor-pointer"
                @click="editSettingEmailById(setting)"
              >
                <icon
                  class="icon opacity-50 lg:opacity-100 cursor-pointer"
                  color="white"
                  icon="edit"
                  height="15"
                  width="15"
                />
              </span>
              <InputBox
                v-model="setting.email"
                type="email"
                :rules="`required`"
                name="Email"
                title="Email"
                label="Email"
                placeholder="email"
                :is-verified="setting.is_email_approved"
                :disabled="true"
                class="flex-1"
                is-requried="true"
              />
            </div>

            <!-- ADDRESS -->
            <div
              class="sm:pr-6 pr-0 w-full sm:pt-0 pt-0"
              :class="[
                (!setting.new_email && setting.new_phone) ||
                (setting.new_email && !setting.new_phone)
                  ? ''
                  : 'sm:w-1/2',
              ]"
            >
              <InputBox
                v-model="setting.address"
                type="text"
                :rules="`required`"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
                :disabled="true"
                class="flex-1"
              />
            </div>
          </div>
          <!-- DIVIDER -->
          <div v-if="index + 1 < contactSettings.length" class="divide mt-4 mb-8"></div>
        </div>
        <!-- V-FOR END -->
        <!-- ADD MORE SETTING BUTTON -->
        <div class="flex font-bold cursor-pointer" @click="appendSetting">
          <icon
            class="icon mr-2"
            style="margin-top: 2px"
            color="primary-green"
            icon="plus"
            height="16"
            width="16"
          />
          <span class="text-primary-green text-sm">Add Contact</span>
        </div>
      </div>
      <!-- LOADING -->
      <div v-else class="h-44 pb-28">
        <Loader class="" :content="true" />
      </div>
      <!-- DIVIDER -->
      <div class="divide mt-8 mb-5"></div>
      <!-- MYQAMPUS CONTACT INFO START -->
      <h1 class="font-roboto relative font-medium text-xs sm:text-2xl text-text-color">
        MyQampus Contact Info
      </h1>
      <!-- WhatsApp -->
      <span class="flex flex-row flex-wrap my-5">
        <icon
          class="icon opacity-50 lg:opacity-100"
          color="primary-green"
          icon="whatsapp"
          height="18"
          width="18"
        />
        <span class="pl-4 text-sm">+92 42 32020867</span>
      </span>
      <!-- Phone Number -->
      <span class="flex flex-row flex-wrap my-5">
        <icon
          class="icon opacity-50 lg:opacity-100"
          color="linkedin-color"
          icon="phone"
          height="18"
          width="18"
        />
        <span class="pl-4 text-sm">+92 42 32020867</span>
      </span>
      <!-- Email -->
      <span class="flex flex-row flex-wrap my-5">
        <icon
          class="icon opacity-50 lg:opacity-100"
          color="text-color-danger"
          icon="message"
          height="18"
          width="18"
        />
        <span class="pl-4 text-sm">support@myqampus.com</span>
      </span>
      <!-- Location -->
      <span class="flex flex-row flex-wrap my-5">
        <icon
          class="icon opacity-50 lg:opacity-100"
          color="mahroon-strong"
          icon="mapMarker"
          height="18"
          width="18"
        />
        <span class="pl-4 text-sm">
          Second Floor,Plaza 35, Street 18,
          <br />
          Sector Z DHA Phase 3, Lahore, Punjab 54792
        </span>
      </span>
    </div>
    <!-- </ValidationObserver> -->
    <ContactSettingModalComponent
      :setting="editSetting"
      :show-modal="showModal"
      :is-edit="isEdit"
      @toggleModal="closeModal"
      @updatedSetting="updatedSetting"
    />
    <UpdatePhoneNumberModal
      :setting="editSetting"
      :show-modal="showPhoneModal"
      :is-edit="isEdit"
      @toggleModal="closeModal"
      @updatedSetting="updatedSetting"
    />
    <UpdateEmailModal
      :setting="editSetting"
      :show-modal="showEmailModal"
      :is-edit="isEdit"
      @toggleModal="closeModal"
      @updatedSetting="updatedSetting"
    />
  </div>
</template>

<script>
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import icon from '@components/icons/icon.vue'
import ContactSettingModalComponent from '@components/Settings/contactSettingModalComponent.vue'
import UpdatePhoneNumberModal from '@/src/components/Settings/updatePhoneNumberModalComponent.vue'
import UpdateEmailModal from '@/src/components/Settings/updateEmailModalComponent.vue'
import generalConstants from '@src/constants/general-constants.js'
import Loader from '@components/BaseComponent/Loader.vue'
/* STORE */
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TitleWrapper,
    InputBox,
    // ValidationObserver,
    PhoneNumber,
    icon,
    ContactSettingModalComponent,
    UpdatePhoneNumberModal,
    UpdateEmailModal,
    Loader,
  },
  page: {
    title: 'System Settings | Contact Settings',
    meta: [
      {
        name: 'description',
        content: 'Contact Settings',
      },
    ],
  },
  data() {
    return {
      contactSettings: [],
      editSetting: {},
      showModal: false,
      showPhoneModal: false,
      showEmailModal: false,
      isEdit: false,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  mounted() {
    this.updateContactSettingArray()
  },
  methods: {
    /**
     * Edit phone number only
     * */
    editSettingPhoneById(setting) {
      this.editSetting = JSON.parse(JSON.stringify(setting))
      this.isEdit = true
      this.openModal('number')
    },
    /**
     * Edit email only
     * */
    editSettingEmailById(setting) {
      this.editSetting = JSON.parse(JSON.stringify(setting))
      this.isEdit = true
      this.openModal('email')
    },
    /**
     * UPDATING Settings Array
     * */
    async updateContactSettingArray() {
      // JSON PARSE & STRINGIFY IS USED FOR COPY WITHOUT REFRENCE
      const [res, err] = await this.getContactSettings()
      this.contactSettings = JSON.parse(JSON.stringify(this.$store.state.settings.contactSettings))
      this.contactSettings.forEach((setting) => {
        setting.phone_field = setting.phone
        setting.new_phone_field = setting.new_phone
      })
    },
    /**
     * Appending Settings Array in old settings
     * */
    appendSetting() {
      const obj = {
        title: '',
        designation: '',
        email: '',
        is_email_approved: true,
        phone: '',
        is_phone_approved: true,
        address: '',
      }
      this.isEdit = false
      this.editSetting = obj
      this.toggleModal()
    },
    /**
     * Toggling Modal */
    toggleModal(arg) {
      switch (arg) {
        case 'email': {
          this.showEmailModal = !this.showEmailModal
          break
        }
        case 'number': {
          this.showPhoneModal = !this.showPhoneModal
          break
        }
        default: {
          this.showModal = !this.showModal
        }
      }
    },
    /**
     * Open Modal */
    openModal(arg) {
      switch (arg) {
        case 'email': {
          this.showEmailModal = true
          break
        }
        case 'number': {
          this.showPhoneModal = true
          break
        }
        default: {
          this.showModal = true
        }
      }
    },
    /**
     * close Modal */
    closeModal(arg) {
      this.showEmailModal = false
      this.showPhoneModal = false
      this.showModal = false
      this.isEdit = false
    },
    /**
     * Editing Setting */
    editSettingById(setting) {
      this.editSetting = JSON.parse(JSON.stringify(setting))
      this.isEdit = true
      this.openModal()
    },
    /**
     * DELETE Setting */
    async deleteSettingById(setting) {
      await this.deleteContactSetting(setting)
      this.updateContactSettingArray()
    },
    /**
     *
     * @description For add and Edit number
     */
    async updatedSetting(newSetting) {
      if (this.isEdit) {
        const [res, err] = await this.updateContactSetting(newSetting)
        if (!err) {
          this.closeModal()
        }
      } else {
        const [res, err] = await this.addNewContactSetting(newSetting)
        if (!err) {
          this.closeModal()
        }
      }
      this.updateContactSettingArray()
    },
    ...mapActions('settings', [
      'getContactSettings',
      'updateContactSetting',
      'addNewContactSetting',
      'deleteContactSetting',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.settings-bar {
  background-color: var(--bg-color-white);
}
.divide {
  border-top: 2px solid #ecedef;
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
.previewImage {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  height: 100%;
  min-height: 100px;
  max-height: 300px;
  border-radius: 35px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
