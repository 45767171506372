<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <!-- MODAL -->
  <!--  eslint-disable vue/no-mutating-props -->
  <UiModalContainer
    footer-classes="flex justify-end"
    :modal-show="showModal"
    :modal-width="60"
    :disable-position-center="true"
    @handleClick="$emit('toggleModal')"
  >
    <template v-slot:header>
      <h1 class="my-2 font-roboto text-base sm:text-lg text-text-color">
        {{ isEdit ? 'Edit' : 'Add' }} Contact Info
      </h1>
    </template>
    <div class="pl-5 pr-5 sm:pr-0 my-5">
      <!-- WHOLE FORM -->
      <div
        class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
      >
        <!-- TITLE -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0">
          <InputBox
            v-model="setting.title"
            type="text"
            :rules="`required`"
            name="title"
            title="Title"
            label="Title"
            placeholder="Title"
            class="flex-1"
            is-requried="true"
          />
        </div>
        <!-- DESIGNATION -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0">
          <InputBox
            v-model="setting.designation"
            type="text"
            :rules="`required`"
            name="Designation"
            title="Designation"
            label="Designation"
            placeholder="Designation"
            class="flex-1"
            is-requried="true"
          />
        </div>
        <!--  PHONE NUMBER -->
        <div v-if="!isEdit" class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0">
          <PhoneNumber
            v-model="setting.phone_field"
            title="Phone Number"
            :rules="`required`"
            @update="updatePhoneNumber"
          />
        </div>
        <!-- EMAIL -->
        <div
          v-if="!isEdit"
          :class="isEdit ? '' : 'pt-4'"
          class="sm:w-1/2 w-full sm:pt-0 sm:pr-6 pr-0"
        >
          <InputBox
            v-model="setting.email"
            type="email"
            :rules="`required`"
            name="Email"
            title="Email"
            label="Email"
            placeholder="email"
            class="flex-1"
            is-requried="true"
          />
        </div>
        <!-- ADDRESS -->
        <div class="w-full sm:pt-0 pt-4 sm:pr-6 pr-0">
          <InputBox
            v-model="setting.address"
            type="text"
            :rules="`required`"
            name="ADRS"
            title="ADRS"
            label="Address"
            placeholder="Address"
            class="flex-1"
          />
        </div>
      </div>
      <!-- SAVE BUTTON -->
      <div class="pb-5 md:pb-0">
        <button
          class="mt-5 mb-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          :disabled="isLoading"
          @click.prevent="handleSubmit(apply(invalid))"
        >
          {{ $t('dashboard.Save') }}
        </button>
      </div>
    </div>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
/* COMPONENTS IMPORT */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'

/* 3rd Party Component */
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapState } from 'vuex'

export default {
  name: 'ContactSettingModalComponent',
  components: {
    UiModalContainer,
    InputBox,
    // ValidationObserver,
    PhoneNumber,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    setting: {
      type: [Object, Array],
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleModal', 'updatedSetting'],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  /* eslint-disable vue/no-mutating-props */
  methods: {
    /**
     * UPDATING NUMBER
     * */
    updatePhoneNumber(payload) {
      this.setting.phone = payload.formattedNumber
      this.setting.phone_field = payload.formattedNumber
    },
    /** applying new setting */
    apply(invalid) {
      if (!invalid) {
        this.$emit('updatedSetting', this.setting)
      }
    },
  },
}
</script>
